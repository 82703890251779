/* React App preconfigured styling */
html {
  background-color: black;
}

.App {
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: black;
  color:black;
}

.App-title, .App-footer {
  min-height: 10vh;
  background-color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(2vh + 2vmin);
  color: #797979;
}

.App-logo {
  min-height: 5vh;
  max-height: 10vh;
  pointer-events: none;
}

.App-spinner {
  min-height: 5vh;
  max-height: 80vh;
  left: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-spinner {
    animation: App-logo-spin infinite 2s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}